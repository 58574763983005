import { useState } from 'react';

const WebSocketComponent = () => {
    const [orderId, setOrderId] = useState('');
    const [token, setToken] = useState('');
    const [webSocket, setWebSocket] = useState(null) as any;

    const handleConnect = () => {
        if (webSocket) {
            webSocket.close();
        }

        const ws = new WebSocket(`wss://stagingapi.healthysure.co/ws/payment-webhook/${orderId}?id=${token}`);

        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.payload && data.payload.payment_status === 'SUCCESS') {
                    ws.close();
                    console.log('success')
                    // postPaymentActions(result);
                    // getPaymentStatus('socket');
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        ws.onclose = () => {
            console.log('WebSocket Disconnected');
        };

        setWebSocket(ws);
    };

    return (
        <div>
            <input
                type="text"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                placeholder="Order ID"
            />
            <input
                type="text"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                placeholder="Token"
            />
            <button onClick={handleConnect}>Connect to WebSocket</button>
        </div>
    );
};

export default WebSocketComponent;
