import { CheckOutlined, CloseOutlined, UndoOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Col, Image, List, Modal, Popover, Radio, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import api from '../../../../api/api';
import noMemberImage from '../../../../assets/icons/blank-state-member.svg';
import TableCTA from '../../../../components/UI/Table/TableCTA';
import TableCTAComponent from '../../../../components/UI/Table/TableCTAComponent';
import TableComponent from '../../../../components/UI/Table/TableComponent';
import Tag from '../../../../components/UI/Tags/Tag';
import Header from '../../../../components/header/Header';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/config';
import { EmpRequestFilters } from '../../../../utils/filters';
import { findStrings, toTitleCase } from '../../../../utils/helper';
import './_requests.scss';
import { errorNotification, successNotification } from '../../../../components/UI/Toast/Toast';

export const renderStatus = (value: string) => {
  return <Tag type={value}>{value}</Tag>;
};

type location = {
  state: {
    integration_id?: string;
    integration_name?: string;
    type?: string;
    subtype?: string;
  };
};

const EmployeeRequests: React.FC = () => {
  const [dataSource, setDataSource] = useState<Array<any>>([]),
    location = useLocation() as location,
    [loading, setLoading] = useState<boolean>(false),
    [selectedRecord, setSelectedRecord] = useState<any>(null),
    [tableLoading, setTableLoading] = useState<boolean>(true),
    [showUndo, setShowUndo] = useState(false),
    [undoTimer, setUndoTimer] = useState<number>(5),
    [actionSubmitted, setActionSubmitted] = useState(false),
    [recordAction, setRecordAction] = useState<any>(),
    [count, setCount] = useState<number>(),
    organisation_id = useSelector((state: any) => state.auth?.organisation_id),
    [params, setParams] = useState({
      limit: parseInt(DEFAULT_PAGE_SIZE),
      offset: parseInt('0'),
      type: '',
      subtype: 'MODIFICATION',
    }) as any,
    [btnLoading, setBtnLoading] = useState(false),
    [checkList, setCheckList] = useState([] as any),
    parseData = (data: any) => {
      const parsedData: object[] = [];
      data.forEach((record: any) => {
        record.modified_fields.forEach((field: any, index: number) => {
          const newRecord: any = {
            ...record,
            nid: `${record?.id}_${index}`,
            modified_field: {
              ...field,
              index,
            },
          };
          if (newRecord?.modified_field?.field_name === 'date_of_birth' && params?.type === '') {
            parsedData.push(newRecord);
          } else if (params?.type !== '') {
            parsedData.push(newRecord);
          }
        });
      });
      return parsedData;
    },
    columns = [
      {
        ...(params?.type !== '' && {
          title: '',
          dataIndex: 'error',
          key: 'error',
          render: (value: any) => {
            if (value && value?.length > 0) {
              return (
                <Popover
                  content={
                    <List
                      dataSource={findStrings(value)}
                      renderItem={(item: any) => (
                        <List.Item>
                          <span>{item}</span>
                        </List.Item>
                      )}
                    />
                  }
                >
                  <ExclamationCircleOutlined style={{ color: 'red' }} />
                </Popover>
              );
            }
            // else {
            //   return <Popover content={
            //     <List dataSource={["This entry is valid and can be added to the platform"]} renderItem={(item: any) => (
            //       <List.Item>
            //         <span>{item}</span>
            //       </List.Item>
            //     )} />
            //   }>
            //     <CheckCircleOutlined style={{ color: 'green' }} />
            //   </Popover>
            // }
          },
        }),
      },
      {
        title: 'Employee ID',
        dataIndex: ['member_detail', 'organisation', 'employee_id'],
        key: 'employee_id',
        render: (value: any, record: any) => {
          return value || record?.employee_id || '-';
        },
      },
      {
        title: 'Employee Name',
        dataIndex: ['member_detail', 'employee_name'],
        key: 'full_name',
        render: (value: any, record: any) => {
          return value || record?.full_name || '-';
        },
      },
      {
        title: 'Member Name',
        dataIndex: ['member_detail', 'full_name'],
        key: 'name',
        render: (value: any) => {
          return value || '-';
        },
      },
      {
        title: 'Relationship',
        dataIndex: ['member_detail', 'relation'],
        key: 'relationship',
        render: (value: any) => {
          return value || '-';
        },
      },

      {
        ...(params?.type === '' &&
          params?.subtype === 'ADDITION' && {
            title: 'Date of Birth',
            dataIndex: ['member_detail', 'date_of_birth'],
            key: 'date_of_birth',
            render: (value: any) => {
              return value ? moment(value).format('DD-MM-YYYY') : '-';
            },
          }),
      },
      {
        ...(params?.type === '' &&
          params?.subtype === 'ADDITION' && {
            title: 'Gender',
            dataIndex: ['member_detail', 'gender'],
            key: 'gender',
            render: (value: any) => {
              return value || '-';
            },
          }),
      },

      {
        ...(params?.type === '' &&
          params?.subtype === 'ADDITION' && {
            title: 'Sum Insured',
            dataIndex: ['policy_details', 'sum_insured_amount'],
            key: 'sum_insured',
            render: (value: any) => {
              return value || '-';
            },
          }),
      },

      {
        ...(params?.subtype === 'MODIFICATION' && {
          title: 'Modified Field',
          dataIndex: ['modified_field', 'field_name'],
          key: 'modified_field',
          render: (value: any) => {
            return value
              ? value
                  .split('_')
                  .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ') || '-'
              : '-';
          },
        }),
      },
      {
        ...(params?.subtype === 'MODIFICATION' &&
          params?.type !== '' && {
            title: 'Request Raised On',
            dataIndex: ['created_at'],
            key: 'requested_on',
            render: (value: any) => {
              return value ? moment(value).format('DD-MM-YYYY') : '-';
            },
          }),
      },
      {
        ...(params?.subtype === 'MODIFICATION' && {
          title: 'Old Value',
          dataIndex: ['modified_field', 'original_value'],
          key: 'old_value',
          render: (value: any) => {
            return value || '-';
          },
        }),
      },
      {
        ...(params?.subtype === 'MODIFICATION' && {
          title: 'New Value',
          dataIndex: ['modified_field', 'updated_value'],
          key: 'updated_value',
          render: (value: any) => {
            return value || '-';
          },
        }),
      },

      {
        ...(params?.subtype !== 'DELETION' && {
          title: 'Status',
          dataIndex: ['provider_status'],
          key: 'type',
          render: (value: string) => {
            return renderStatus(toTitleCase(value));
          },
        }),
      },
      {
        ...(params?.type === '' && {
          title: '',
          dataIndex: 'edit',
          key: 'edit',
          fixed: 'right',
          render: (value: any, record: any) => {
            return showUndo && record?.id === selectedRecord?.id ? (
              <div className="actions">
                <span
                  className="cta undo"
                  onClick={() => {
                    handleUndo();
                  }}
                >
                  <UndoOutlined /> Undo ({undoTimer}s)
                </span>
              </div>
            ) : (
              <div className="actions">
                <span
                  className="cta accept"
                  onClick={() => {
                    setSelectedRecord(record);
                    askConfirmation('accept', {
                      record: record,
                      action: 'ACCEPTED',
                      quantity: 1,
                    });
                  }}
                >
                  <CheckOutlined /> Accept
                </span>
                <span
                  className="cta reject"
                  onClick={() => {
                    setSelectedRecord(record);
                    askConfirmation('reject', {
                      record: record,
                      action: 'REJECTED',
                      quantity: 1,
                    });
                  }}
                >
                  <CloseOutlined /> Reject
                </span>
              </div>
            );
          },
        }),
      },
    ],
    locale = {
      emptyText: (
        <div className="empty-table">
          <div className="icon">
            <Image src={noMemberImage} preview={false} width={100} />
          </div>
          <div className="text">No requests here</div>
        </div>
      ),
    },
    askConfirmation = (type: string, action: object) => {
      Modal.confirm({
        title: 'Attention Please',
        content: `Are you sure you want to ${type} this request?`,
        okText: `YES ${type.toUpperCase()}`,
        cancelText: 'CANCEL',
        onOk: () => {
          handleClick(action);
        },
      });
    },
    onConfirm = () => {
      if (!(recordAction && recordAction?.record && recordAction?.action)) {
        return;
      }
      api.members
        .employeeRequestAction([
          {
            id: recordAction?.record?.id,
            request: recordAction?.action,
          },
        ])
        .then((res: any) => {
          getTableData();
          successNotification(
            `Modification request of ${recordAction?.record?.member_detail?.full_name}(${
              recordAction?.record?.member_detail?.relation
            }) has been ${recordAction?.action?.toLowerCase()}`
          );
        })
        .catch((err: any) => {
          errorNotification(err?.response?.data?.message || err);
        });
    },
    handleClick = (action: any) => {
      setRecordAction(action);
      setShowUndo(true);
      setActionSubmitted(false);
      setUndoTimer(5);
    },
    handleUndo = () => {
      setShowUndo(false);
      setUndoTimer(5);
      setActionSubmitted(false);
    },
    getTableData = () => {
      setTableLoading(true);
      const paramsToSend = {
        ...params,
        type: params?.subtype,
        request: params?.type === 'ALL' ? 'ACCEPTED' : 'PENDING',
      };
      delete paramsToSend.subtype;
      api.members.getModificationRequests(paramsToSend).then((res: any) => {
        setTableLoading(false);
        if (res?.data?.results) {
          const data = params?.subtype === 'MODIFICATION' ? parseData(res?.data?.results) : res?.data?.results;
          setDataSource(data || []);
          setCount(res?.data?.count || 0);
        }
      });
    },
    getParams = (filter: object) => {
      setParams((prev: any) => {
        return { ...prev, ...filter, offset: 0, limit: 10 };
      });
    },
    currentPageHandler = (values: { limit: number; offset: number }) => {
      setParams((prev: any) => {
        return { ...prev, ...values };
      });
    },
    resetParams = (reset?: boolean) => {
      setParams((prev: any) => {
        return {
          limit: parseInt(DEFAULT_PAGE_SIZE),
          offset: 0,
          type: prev?.type,
        };
      });
    },
    approveAllRequests = () => {
      api.members
        .employeeRequestAction([
          ...checkList?.map((idx: any) => {
            const item = dataSource[idx];
            return {
              id: item?.id,
              request: 'ACCEPTED',
            };
          }),
        ])
        .then((res: any) => {
          getTableData();
          successNotification('All selected requests accepted');
        })
        .catch((err: any) => {
          errorNotification(err?.response?.data?.message || err);
        });
    },
    rejectAllRequests = () => {
      api.members
        .employeeRequestAction([
          ...checkList?.map((idx: any) => {
            const item = dataSource[idx];
            return {
              id: item?.id,
              request: 'REJECTED',
            };
          }),
        ])
        .then((res: any) => {
          getTableData();
          successNotification('All selected requests rejected');
        })
        .catch((err: any) => {
          errorNotification(err?.response?.data?.message || err);
        });
    },
    notices = [
      {
        type: 'ALL',
        value: <span>These requests have been raised as endorsements directly, you don't need to approve them.</span>,
      },
      {
        type: '',
        value: (
          <span>
            Changes in date of birth and addition of dependents may require an additional premium. Once you accept the
            request, an endorsement will be raised.
          </span>
        ),
      },
    ];

  useEffect(() => {
    if (organisation_id) {
      setParams((prev: any) => {
        return { ...prev, organisation_id: organisation_id };
      });
    }
  }, [organisation_id]);

  useEffect(() => {
    getTableData();
  }, [params]);

  useEffect(() => {
    if (showUndo && !actionSubmitted) {
      const countdown = setInterval(() => {
        setUndoTimer((prev) => prev - 1);
        if (undoTimer <= 0) {
          setShowUndo(false);
          setActionSubmitted(true);
          onConfirm();
        }
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [showUndo, undoTimer, recordAction, actionSubmitted]);

  useEffect(() => {
    if (location?.state?.integration_id) {
      setParams((prev: any) => {
        return { ...prev, integration_id: location?.state?.integration_id };
      });
    }
  }, [location?.state?.integration_id]);

  if (loading) {
    return (
      <div className="loader-div">
        <div className="loader">
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* {visible && (
        <EditMember
          type={params?.type}
          visible={visible}
          onClose={(values?: any) => {
            setSelectedRecord(null);
            if (values) {
              setDataSource((prev: any) => {
                return prev.map((item: any) => {
                  if (item?.id === values?.id) {
                    return values;
                  } else {
                    return item;
                  }
                });
              });
            }
          }}
          record={record}
        />
      )} */}

      {/* <CustomModal
        isModalVisible={modalVisible}
        confirmHandler={onConfirm}
        cancelHandler={() => {
          setModalVisible(false);
        }}
        title={recordAction?.action === 'approve' ? 'Approve Request' : 'Reject Request'}
        cancelButtonText="NO"
        confirmButtonText="YES"
      /> */}
      <div className="employee-requests">
        <Header title="Employee Requests" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            borderBottom: '2px solid #E9E9EA',
          }}
        >
          <Col span={24}>
            <div className="empreq-table-cta">
              <TableCTA
                getParams={getParams}
                filters={EmpRequestFilters}
                showSearch={false}
                filterKey="type"
                searchKey="search"
              />
            </div>
          </Col>
        </Row>

        {notices?.find((item) => item?.type === params?.type) && (
          <Alert
            className="alert-container mt-12 mb-12"
            message={notices?.find((item) => item?.type === params?.type)?.value}
            type="info"
            showIcon
          ></Alert>
        )}

        <TableCTAComponent
          params={params}
          getParams={getParams}
          resetParams={resetParams}
          disableDivider={params?.type !== ''}
          customComponentTwo={
            <Radio.Group
              className="emp-request-table-cta-btns"
              value={params?.subtype}
              onChange={(e) => getParams({ subtype: e.target.value })}
            >
              <Radio.Button type="default" value="MODIFICATION">
                Modification
              </Radio.Button>
              <Radio.Button value="ADDITION">Addition</Radio.Button>
              {params?.type !== '' && <Radio.Button value="DELETION">Deletion</Radio.Button>}
            </Radio.Group>
          }
          search={{
            placeholder: 'Search',
            key: 'full_name',
          }}
          cta={{
            button: [
              ...(params?.type === ''
                ? [
                    {
                      text: 'Approve All',
                      loading: btnLoading,
                      disabled: checkList?.length === 0,
                      onClick: () => {
                        Modal.confirm({
                          title: 'Attention Please!',
                          content: `Are you sure you want to approve ${checkList?.length} requests?`,
                          okText: 'YES ACCEPT ALL',
                          cancelText: 'CANCEL',
                          onOk: () => {
                            approveAllRequests();
                          },
                        });
                      },
                    },
                    {
                      text: 'Reject All',
                      loading: btnLoading,
                      disabled: checkList?.length === 0,
                      onClick: () => {
                        Modal.confirm({
                          title: 'Attention Please!',
                          content: `Are you sure you want to reject ${checkList?.length} requests?`,
                          okText: 'YES REJECT ALL',
                          cancelText: 'CANCEL',
                          onOk: () => {
                            rejectAllRequests();
                          },
                        });
                      },
                    },
                  ]
                : []),
            ],
          }}
        />
        <TableComponent
          loading={tableLoading}
          {...(params?.type === '' ? { getCheckList: setCheckList } : {})}
          currentPageHandler={currentPageHandler}
          count={count}
          dataSource={dataSource}
          columns={columns.filter((col) => Object.keys(col).length !== 0)}
          urlOffset={params.offset}
          urlLimit={params.limit}
          showPagination={true}
          locale={locale}
          setRowKey="nid"
        />
      </div>
    </React.Fragment>
  );
};

export default EmployeeRequests;
