import {
  CloseOutlined,
  FileDoneOutlined,
  GiftOutlined,
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { Col, Image, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {thunks} from '../../thunks/auth';
import { CustomModal } from '../Modals/CustomModal';
import EmployerEmployeeToggle from '../UI/Toggle/EmployerEmployeeToggle';
import { trackData } from '../../utils/trackers';

interface Props {
  close: any;
  visible: boolean;
}

interface MenuProps {
  name: string;
  icon: any;
  link: string;
}

const NavbarMobile: React.FC<Props> = ({ close, visible }) => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    logoutHandler = () => {
      dispatch(thunks.logout());
    },
    name = useSelector((state: any) => state.auth.name),
    [modalVisible, setModalVisible] = useState(false),
    role = useSelector((state: any) => state.auth.roles),
    activeMode = useSelector((state: any) => state.auth.activeMode),
    [activeMenu, setActiveMenu] = useState<Array<MenuProps>>([]),
    themeDetails = useSelector((state: any) => state.theme),
    userDetails = useSelector((state: any) => state.data.userDetails);
  useEffect(() => {
    if (activeMode === 'employer') {
      setActiveMenu(employerMenu);
    } else {
      setActiveMenu(employeeMenu);
    }
  }, [activeMode]);

  const employeeMenu = [
    {
      name: 'Home',
      icon: <HomeOutlined />,
      link: '/home',
    },
    {
      name: 'Claims',
      icon: <FileDoneOutlined />,
      link: '/claims',
    },
    // {
    //     name: 'Settings',
    //     icon: <SettingOutlined />,
    //     link: '/settings'
    // },
    // {
    //     name: 'Notifications',
    //     icon: <BellOutlined />,
    //     link: '/notifications'
    // },
    // {
    //     name: 'Help',
    //     icon: <QuestionCircleOutlined />,
    //     link: '/help'
    // }
  ];

  const employerMenu = [
    {
      name: 'Dashboard',
      icon: <HomeOutlined />,
      link: '/dashboard',
    },
    {
      name: 'Members',
      icon: <TeamOutlined />,
      link: '/members',
    },
    {
      name: 'Claims',
      icon: <FileDoneOutlined />,
      link: '/claims',
    },
    {
      name: 'Settings',
      icon: <SettingOutlined />,
      link: '/settings'
    },
    // {
    //     name: 'Notifications',
    //     icon: <BellOutlined />,
    //     link: '/notifications'
    // },
    // {
    //     name: 'Help',
    //     icon: <QuestionCircleOutlined />,
    //     link: '/help'
    // }
    ],
    trackAndRedirect = () => {
      trackData("View Retail Offers",{"source":"navbar"})
      navigate('retail/?utm_source=platform&utm_medium=navbar_btn_mobile&utm_campaign=employee_offer')
    };

  return (
    <React.Fragment>
      <CustomModal
        isModalVisible={modalVisible}
        confirmHandler={logoutHandler}
        cancelHandler={() => {
          setModalVisible(false);
        }}
        title={`Are you sure you want to logout?`}
        cancelButtonText="NO"
        confirmButtonText="YES"
      />

      <div className={`navbar-mobile ${visible ? 'slide-in' : 'slide-out'}`}>
        <Row justify="space-between" align="middle" className="navbar-header">
          <Col>
            {/* <Image className="pointer" src={logo} preview={false} onClick={() => navigate('/')} /> */}
            <Image className="pointer" src={themeDetails?.logoUrl} preview={false} onClick={() => navigate('/')} />

          </Col>
          <Col>
            <CloseOutlined className='navbar-mobile-icon' onClick={close} />
          </Col>
        </Row>

        <Row justify="space-between" align="middle" className="navbar-profile">
          <Col>{name}</Col>
        </Row>
        {role.includes('employer') && (
          <div className="navbar-toggle">
            <EmployerEmployeeToggle text={'navbar-toggle-text'} toggle={'navbar-toggle-switch'} />
          </div>
        )}

        <hr className="navbar-divider" />

        <div className="navbar-menu">
          {activeMenu.map((item, index) => (
            <NavLink
              to={item.link}
              key={index}
              className={(navData) => (navData.isActive ? 'navbar-menu-item active' : 'navbar-menu-item')}
            >
              <Row className="navbar-menu-item-group" onClick={close}>
                <Col className="navbar-menu-item-icon">{item.icon}</Col>
                <Col className="navbar-menu-item-name">{item.name}</Col>
              </Row>
            </NavLink>
          ))}
          {activeMode === 'employee' && userDetails?.has_offers && <div className="navbar-items">
            <div onClick={() => trackAndRedirect()}
              className='navbar-menu-item'>
              <Row className="navbar-menu-item-group" onClick={close}>
                <Col className="navbar-menu-item-icon"><GiftOutlined /></Col>
                <Col className="navbar-menu-item-name">Offers</Col>
              </Row>
            </div>
          </div>}

        </div>

        <div className="navbar-footer navbar-menu-item">
          <Row
            className="navbar-menu-item-group"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Col className="navbar-menu-item-icon">
              <LogoutOutlined />
            </Col>
            <Col className="navbar-menu-item-name">Logout</Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavbarMobile;
