export const ClaimsFilters = [
  {
    id: 1,
    title: 'All',
    value: '',
  },
  {
    id: 2,
    title: 'In Process',
    value: 'IN_PROCESS',
  },
  {
    id: 3,
    title: 'Settled',
    value: 'SETTLED',
  },
  {
    id: 4,
    title: 'Rejected',
    value: 'REJECTED',
  },
];

export const MemberFilters = [
  // {
  //   id: 1,
  //   title: 'All',
  //   value: '',
  // },
  {
    id: 2,
    title: 'Active',
    value: 'ACTIVE',
  },
  {
    id: 3,
    title: 'Invited',
    value: 'INVITED',
  },
  {
    id: 4,
    title: 'Addition In Process',
    value: 'ADDITION_IN_PROCESS',
  },
  {
    id: 6,
    title: 'Modification In Process',
    value: 'MODIFICATION_IN_PROCESS',
  },
  {
    id: 7,
    title: 'Deletion In Process',
    value: 'DELETION_IN_PROCESS',
  },
  {
    id: 8,
    title: 'Inactive',
    value: 'INACTIVE',
  },
];

export const HRMSMemberFilters = [
  {
    id: 1,
    title: 'All Members',
    value: '',
  },
  {
    id: 2,
    title: 'Addition',
    value: 'ADDITION',
  },
  {
    id: 3,
    title: 'Deletion',
    value: 'DELETION',
  },
  // {
  //   id: 4,
  //   title: 'Modification',
  //   value: 'MODIFICATION',
  // },
];

export const EmpRequestFilters = [
  {
    id: 1,
    title: 'Pending Approvals',
    value: '',
  },
  {
    id: 2,
    title: 'All Requests',
    value: 'ALL',
  },
];
