import axios from 'axios';
import { createBrowserHistory } from 'history';
import { default as qs } from 'qs';
import { deleteLocalData, getRetailAccessToken, getRetailRefreshToken, removeAxiosHeaders, setRetailAccessToken, setRetailRefreshToken } from '../utils/helper';
import apiEndpoints from './apiEndPoints';

const retailAxios = axios.create();

const history = createBrowserHistory({});
let isTokenrefreshing = false,
  retryReqestArray: any = [];

function onTokenRefresh(authorisationToken: any) {
  retryReqestArray.map((cb: any) => cb(authorisationToken));
}

axios.interceptors.response.use(
  (response) => {
    // window.console.log(response.status);
    return response;
  },
  (error) => {
    // window.console.log(error.response);
    if (error && error.response) {
      if (error.response.status === 401) {
        if (getRetailAccessToken()) {
          if (getRetailAccessToken()) {
            if (!isTokenrefreshing) {
              removeAxiosHeaders();
              isTokenrefreshing = true;
              const params = {
                grant_type: 'refresh_token',
                refresh_token: getRetailRefreshToken(),
              };
              axios
                .post(apiEndpoints.refreshToken, params)
                .then((response) => {
                  setRetailRefreshToken(response.data.refresh_token);
                  setRetailAccessToken(response.data.access_token);
                  // setAxiosHeaders();
                  onTokenRefresh(response.data.access_token);
                  window.location.reload();
                })
                .catch(() => {
                  removeAxiosHeaders();
                  deleteLocalData('retailAccessToken');
                  history.push('/');
                  window.location.reload();
                });
            }
            return new Promise((resolve) => {
              retryReqestArray.push((token: any) => {
                const originalRequest = error.config;
                originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(axios(originalRequest));
              });
            });
          } else {
            removeAxiosHeaders();
            deleteLocalData('retailAccessToken');
            history.push('/');
            window.location.reload();
          }
        }
      }
      // if (error.response.status === 404) {
      //   history.push('/403');
      //   window.location.reload();

      // }
      // if (error.response.status >= 500) { history.push('/500'); window.location.reload(); }
    }
    return Promise.reject(error);
  }
);

retailAxios.interceptors.request.use((request: any) => {
  let requestPlatform = 'web';
  try {
  } catch (e) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      requestPlatform = 'm-web';
    }
  }
  request.headers.common['Content-Type'] = 'application/json';
  request.withCredentials = true;
  if (getRetailAccessToken()) {
    request.headers.common['Authorization'] = 'Bearer ' + getRetailAccessToken();
  }
  request.headers['X-REQUEST-PLATFORM'] = requestPlatform;
  return request;
});

const retailApi = {
  //retail
  user: {
    requestOTP: (data: any) => axios.post(apiEndpoints.retail_requestOTP, data).then((res) => res.data),
    verifyOTP: (data: any) => axios.post(apiEndpoints.retail_verifyOTP, data).then((res) => res.data),
    profile: () => axios.get(apiEndpoints.retail_userProfile).then((res) => res.data),
    updateProfile: (data: any) => axios.post(apiEndpoints.retail_userProfile, data).then((res) => res.data),
    getChoices: (params?: object) => axios.get(apiEndpoints.retail_filter, { params: params }).then((res) => res.data),
  },
  partner: {
    getPartner: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.retail_getPartner, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          cancelToken: cancelToken,
        },
        )
        .then((res) => res.data),

    getProducts: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.retail_getProducts, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),

    getProductsV2: (params: object, cancelToken?: any) =>
      axios
        .get(apiEndpoints.retail_plansAndOffers, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),

    getSlugProductsV2: (params: object, cancelToken?: any) =>
      axios
        .get(apiEndpoints.retail_slugPlansAndOffers, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),

    // getProductV2: (params?: object) => axios.get(apiEndpoints.manageCart, { params: params }).then((res) => res.data),
    redeemOffer: (data: object) => axios.post(apiEndpoints.retail_redeemOffer, data).then((res) => res.data),

    getPartnerDetails: (params?: object) => axios.get(apiEndpoints.retail_partnerDetails, { params: params }).then((res) => res.data),

  },
  cart: {
    manageCart: (data: object) => axios.post(apiEndpoints.retail_manageCart, data).then((res) => res.data),
    clearCart: (id: number, params?: object) => axios.delete(apiEndpoints.retail_manageCart + `/${id}`, { params: params }).then((res) => res.data),
    getCart: (params?: object) => axios.get(apiEndpoints.retail_manageCart, { params: params }).then((res) => res.data),
    cartCount: (params?: object) => axios.get(apiEndpoints.retail_cartCount, { params: params }).then((res) => res.data),
  },
  payments: {
    getPaymentLink: (data: object) => axios.post(apiEndpoints.retail_paymentRequest, data).then((res) => res.data),
    getPaymentLinkV1: (data: object) => axios.post(apiEndpoints.retail_paymentRequestV1, data).then((res) => res.data),
    getPaymentStatus: (data: object) => axios.post(apiEndpoints.retail_paymentStatus, qs.stringify(data)).then((res) => res.data),
  },
  coupons: {
    purchasedCoupons: (data: any) => axios.post(apiEndpoints.retail_purchasedCouponsV1, (data = data)).then((res) => res.data),
  }
};

export default retailApi;
